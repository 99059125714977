<template>
  <v-dialog v-model="visible" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text class="text-center">
        <span>{{ label }}</span>

        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0 mt-1"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LoadingModal",

  props: {
    label: {
      default: "Carregando...",
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>