<template>
  <v-card>
    <LoadingModal :visible="loading" />

    <v-card-title>
      <span class="headline">
        {{ id ? "Editar processo" : "Cadastrar processo" }}
      </span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <v-select
                :rules="requiredRule"
                :items="users"
                item-text="name"
                item-value="id"
                v-model="processo.user_active"
                label="Polo Ativo"
                :loading="loadingUsers"
                :error-messages="errors.user_active"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :rules="requiredRule"
                :items="users"
                item-text="name"
                item-value="id"
                v-model="processo.user_passive"
                label="Polo Passivo"
                :loading="loadingUsers"
                :error-messages="errors.user_passive"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Titulo do processo"
                v-model="processo.title"
                :error-messages="errors.title"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Numero do processo"
                v-model="processo.number"
                :error-messages="errors.number"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Materia"
                  :items="materias"
                  item-text="name"
                  item-value="id"
                  v-model="processo.material_id"
                  :loading="loadingMaterias"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Tipo de ações"
                  :items="acoes"
                  item-text="name"
                  item-value="id"
                  v-model="processo.action_id"
                  :loading="loadingAcoes"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Tipo de Objeto"
                  :items="objetos"
                  item-text="name"
                  item-value="id"
                  v-model="processo.object_id"
                  :loading="loadingObjetos"
                  :error-messages="errors.object_id"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Descrição do Objeto"
                v-model="processo.description"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Tipo de Rito"
                  :items="ritos"
                  item-text="name"
                  item-value="id"
                  v-model="processo.rite_id"
                  :loading="loadingRitos"
                  :error-messages="errors.rite_id"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Pasta"
                v-model="processo.folder"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Data da Distribuição"
                type="date"
                v-model="processo.distribution"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Data da Citação"
                type="date"
                v-model="processo.citation"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Processo Eletrônico"
                  :items="tipoBoleanos"
                  v-model="processo.electronic"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Processo Estratégico"
                  :items="tipoBoleanos"
                  v-model="processo.strategic"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Probabilidade de Exito"
                  :items="probalidades"
                  v-model="processo.probability"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <v-text-field
                label="Valor da Causa"
                v-model="processo.cause"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                required
                type="date"
                v-model="processo.liquidation"
                label="Data de Referência da Liquidação"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Valor Provisionado"
                v-model="processo.provisioned_value"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="date"
                label="Data do Valor Provisionado"
                v-model="processo.provisioned"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Valor da contigencia"
                v-model="processo.contingency"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Risco (%)"
                v-model="processo.risk"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                type="date"
                label="Data do Status"
                v-model="processo.status_date"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Status"
                :items="status"
                v-model="processo.status"
              ></v-select>
            </v-col>
            <!--  <v-col cols="4">
              <v-select
                label="Prioridade de"
                :items="users"
                item-value="id"
                item-text="name"
                v-model="processo.prioridade"
                :loading="loadingUsers"
              ></v-select>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                type="date"
                label="Data da fase"
                v-model="processo.date_phase"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Fase"
                  :items="fases"
                  item-text="name"
                  item-value="id"
                  v-model="processo.phase_id"
                  :error-messages="errors.phase_id"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Tipo de Instancia"
                  :items="instancias"
                  item-value="id"
                  item-text="name"
                  v-model="processo.instance_id"
                  :loading="loadingInstancias"
                  :error-messages="errors.instance_id"
                ></v-select>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Unidade"
                  :items="unidades"
                  item-value="id"
                  item-text="name"
                  v-model="processo.unit_id"
                  :loading="loadingUnidades"
                  :error-messages="errors.unit_id"
                ></v-select>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Comarca"
                  :items="comarcas"
                  item-value="id"
                  item-text="name"
                  v-model="processo.district_id"
                  :loading="loadingComarcas"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-row align-center">
                <v-select
                  label="Órgão"
                  :items="orgaos"
                  item-value="id"
                  item-text="name"
                  v-model="processo.agency_id"
                  :loading="loadingOrgaos"
                ></v-select>
              </div>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-spacer></v-spacer>
            <v-col cols="12">
              <v-btn color="primary" @click="salvarProcesso"> Salvar</v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import LoadingModal from "@/components/Loading/Modal/Index";
import ToastService from "@/services/ToastService";
import UserService from "@/services/User/UserService";
import instanceAxios from "../../plugins/axios";
export default {
  components: {
    LoadingModal,
  },

  computed: {
    requiredRule: function () {
      return [(v) => !!v || "Campo é obrigatorio"];
    },
  },

  data() {
    return {
      errors: [],
      UserService: new UserService(""),
      users: [],
      loadingUsers: true,

      tipoBoleanos: [
        { value: 1, text: "Sim" },
        { value: 0, text: "Não" },
      ],
      probalidades: ["alta", "media", "baixa"],
      status: ["ativo", "arquivado", "inativo", "suspenso"],
      loading: false,
      id: undefined,
      processo: {
        user_passive: 1,
        user_active: 2,
        title: "",
        material_id: 1,
        action_id: 1,
        district_id: 1,
        agency_id: 1,
      },

      materias: [],
      materiasModalShow: false,
      materiasModal: {},
      loadingMaterias: true,

      acoes: [],
      acoesModalShow: false,
      acoesModal: {},
      loadingAcoes: true,

      objetos: [],
      objetosModalShow: false,
      objetosModal: {},
      loadingObjetos: true,

      ritos: [],
      ritosModalShow: false,
      ritosModal: {},

      fases: [],
      fasesModalShow: false,
      fasesModal: {},
      loadingRitos: true,

      instancias: [],
      instanciasModalShow: false,
      instanciasModal: {},
      loadingInstancias: true,

      unidades: [],
      unidadesModalShow: false,
      unidadesModal: {},
      loadingUnidades: true,

      especialidades: [],
      especialidadesModalShow: false,
      especialidadesModal: {},

      comarcas: [],
      comarcasModalShow: false,
      comarcasModal: {},
      loadingComarcas: true,

      orgaos: [],
      orgaosModalShow: false,
      orgaosModal: {},
      loadingOrgaos: true,
    };
  },

  methods: {
    async salvarProcesso() {
      try {
        await instanceAxios.post("lawsuits", this.processo);

        this.processo = {};

        ToastService("Processo criado.", "success");
      } catch (error) {
        if (typeof error.response.data === "object") {
          this.errors = error.response.data.errors;
          return ToastService("Verifique o preenchimento dos dados.", "error");
        }
        ToastService("Erro ao criar o processo.", "error");
      }
    },
    async getMaterias() {
      this.loadingMaterias = true;

      const { data } = await instanceAxios.get("materials");

      this.materias = data;

      this.loadingMaterias = false;
    },

    async getAcoes() {
      this.loadingAcoes = true;

      const { data } = await instanceAxios.get("actions");

      this.acoes = data;

      this.loadingAcoes = false;
    },

    async getObjetos() {
      this.loadingObjetos = true;
      const { data } = await instanceAxios.get("objects");

      this.objetos = data;
      this.loadingObjetos = false;
    },

    async getRitos() {
      this.loadingRitos = true;

      const { data } = await instanceAxios.get("rites");

      this.ritos = data;

      this.loadingRitos = false;
    },

    async getFases() {
      this.loadingFases = true;
      const { data } = await instanceAxios.get("phases");

      this.fases = data;

      this.loadingFases = false;
    },

    async getInstancias() {
      this.loadingInstancias = true;
      const { data } = await instanceAxios.get("instances");

      this.instancias = data;

      this.loadingInstancias = false;
    },

    async getUnidades() {
      this.loadingUnidades = true;
      const { data } = await instanceAxios.get("units");

      this.unidades = data;

      this.loadingUnidades = false;
    },

    async getComarcas() {
      this.loadingComarcas = true;

      const { data } = await instanceAxios.get("districts");
      this.comarcas = data;

      this.loadingComarcas = false;
    },

    async getOrgaos() {
      this.loadingOrgaos = true;
      const { data } = await instanceAxios.get("agencys");
      this.orgaos = data;

      this.loadingOrgaos = false;
    },

    async showUsers() {
      try {
        this.loadingUsers = true;

        this.users = await this.UserService.index("users");

        this.loadingUsers = false;
      } catch (exception) {
        this.loadingUsers = false;
        console.log(exception);
      }
    },
  },

  mounted() {
    const id = this.$route.params.id;
    if (id !== undefined) {
      this.id = id;
      this.loading = true;
    }

    this.getMaterias();
    this.getAcoes();
    this.getObjetos();
    this.getRitos();
    this.showUsers();
    this.getFases();
    this.getInstancias();
    this.getUnidades();
    this.getComarcas();
    this.getOrgaos();
  },
};
</script>

<style></style>
