import ErrorService from "@/services/ErrorService";
import RequestService from "@/services/RequestService";

export default class UserService extends RequestService {
  constructor(baseEndpoint) {
    super(baseEndpoint);
  }

  index = async (endpoint) => {
    try {
      const { data } = await this.get(endpoint);

      return data;
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  show = async (id) => {
    try {
      const { data } = await this.get(id);

      return data;
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  storeOrUpdate = async (endpoint, params) => {
    try {
      await this.postOrPut(endpoint, params);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  destroy = async (id) => {
    try {
      await this.delete(id);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };
}
